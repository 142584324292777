import * as React from "react";
import "./procedure-type.css";
import Layout from "../layouts/layout";
import parse from 'html-react-parser';
import favicon from "../images/favico.ico";
import { Helmet } from "react-helmet";



const ProcedureTypePage = (props) => {
    const { content } = props.pageContext;
    const { title } = props.pageContext;
    const { titleMeta } = props.pageContext;
    const { metaDesc } = props.pageContext;
    const { featuredImage } = props.pageContext;
    const { uri } = props.pageContext;
    return (
        <Layout>
            <Helmet htmlAttributes={{
                lang: 'fr-FR',
            }}>
                <link rel="icon" type="image/x-icon" href={favicon} />
                <title>{titleMeta}</title>
                <meta name="description" content={metaDesc} />
                <meta name="robots" content="index, follow" />
                <link data-react-helmet="true" rel="canonical" href={"https://www.medica-tour.fr" + uri}></link>
                <meta name="msvalidate.01" content="BF6180D2B9843FD051D8456A095B9C38" />
                <script async src="https://www.googletagmanager.com/gtag/js?id=AW-10925153812"></script>
    <script>
      {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'AW-10925153812');
      `}
    </script>
            </Helmet>
            <div className="container-fluid service pt-3 pb-5">
                <div className="container intern-page-style pt-5">
                    <h1 className="service-title">
                        {title}
                    </h1>
                </div>
            </div>

            <div className="container-fluid pt-2 blog-single">
                <div className="container ">
                    <div className="row">
                        {content && parse(content)}
                    </div>
                </div>
            </div>

            {/* free devis block */}
            {/* <div className="container-fluid pb-3 pt-3 free-devis-bloc">
                <div className="container pt-4">

                    <div className="row d-flex justify-content-center">
                        <div className="col-sm-12 col-md-6 text-center">
                            <p className="float-start text-devis-block pt-2">Demandez gratuitement votre devis</p>
                        </div>

                        <div className="col-sm-12 col-md-6 text-center">
                            <Link to="#" className="d-none d-sm-none d-md-block float-end btn btn-devis-free" role="button" aria-pressed="true">DEVIS GRATUIT</Link>
                            <Link to="#" className="d-sm-block d-md-none btn  btn-devis-free" role="button" aria-pressed="true">DEVIS GRATUIT</Link>
                        </div>
                    </div>
                </div>
            </div> */}
        </Layout>
    )
}


export default ProcedureTypePage
